import { evolvingStoryTeaserHasImage, hasLabelOnEvolvingStories } from '@hubcms/brand';
import { ImageAspectRatio, ImageSizes } from '@hubcms/domain-images';
import { Labels } from '@hubcms/ui-taxonomy';
import { DurationIndicator } from '@hubcms/ui-temporal';
import { Heading, Logo, Placeholder } from '@mediahuis/chameleon-react';
import cx from 'classnames';
import { ReactElement } from 'react';

import { NativeTeaserImage } from '../NativeTeaserImage';
import { TeaserLink } from '../TeaserLink';

import { EvolvingStoryTeaserProps } from './types';
import styles from './evolving-story-teaser.module.scss';
import { getDefaultImageDetails, getImageDetails } from '../../utils/getImageDetails';

const sizes: ImageSizes = {
  sm: '384px',
  md: '512px',
  lg: '640px',
};

const EvolvingStoryTeaser = ({ data, imageFormat, singleEvolvingStory }: EvolvingStoryTeaserProps): ReactElement | null => {
  if (!data.url || !data.title) {
    return null;
  }
  const image = data.image ? getImageDetails(data.image, imageFormat) : getDefaultImageDetails(imageFormat);
  const shouldRenderImage = evolvingStoryTeaserHasImage && (!!(image && image.url) || data.contentType === 'extraPage');

  return (
    <article className={styles.fullHeight}>
      <TeaserLink url={data.url} className={styles.link} trackingData={data.trackingData}>
        <div
          className={cx(styles.teaser, styles.fullHeight, {
            [styles.singleEvolvingStory]: singleEvolvingStory,
            [styles.withSquareImage]: data.contentType === 'podcast',
          })}
        >
          <div className={styles.title}>
            {data.isPremium && <Logo name="brand-plus-main" className={styles.premiumLogo} />}
            {hasLabelOnEvolvingStories && <Labels className={styles.labels} sublabel={data.subLabel} label={data.label} />}
            <Heading level={3} className={styles.heading}>
              {data.title}
            </Heading>

            {['liveVideo', 'video', 'podcast'].includes(data.contentType) && data.duration && (
              <DurationIndicator className={styles.duration}>{data.duration}</DurationIndicator>
            )}
          </div>

          {shouldRenderImage && (
            <div className={cx(styles.image, imageFormat)}>
              {data.image ? (
                <NativeTeaserImage
                  image={data.image}
                  format={imageFormat}
                  sizes={sizes}
                  className={styles.fullHeight}
                  data-testid="teaser-image"
                />
              ) : (
                <Placeholder aspectRatio={`${ImageAspectRatio[imageFormat].width}:${ImageAspectRatio[imageFormat].height}`} />
              )}
            </div>
          )}
        </div>
      </TeaserLink>
    </article>
  );
};

export default EvolvingStoryTeaser;
